<template>
    <b-card>
        <Form
            ref="createCategory"
            :category="category"
            :submit="submit"
        />
    </b-card>
</template>

<script>
import Form from "./partials/Form";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "EditCategory",

    components: {
        Form
    },

    data() {
        return {
            id: this.$route.params.id
        }
    },

    computed: {
        ...mapGetters('category', [
            'categoryById'
        ]),
        category() {
            let category = this.categoryById(this.id)
            if (!category) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.$router.push({ name: 'category' })

                return
            }
            category.keywords = category.keywords ? category.keywords.split(',') : []
            category.body = category.body ?? ''

            return category
        }
    },

    methods: {
        ...mapActions({
            editCategory: 'category/edit',
        }),
        submit() {
            const refCategory = this.$refs.createCategory

            refCategory.loading = true
            refCategory.errMessage = null

            const data = JSON.parse(JSON.stringify(refCategory.category))
            data.keywords = data.keywords.toString()
            this.editCategory(data).then(() => {
                refCategory.loading = false
                this.$router.push({ name: 'category' })
            }).catch(response => {
                refCategory.loading = false
                if (response.errors) {
                    refCategory.$refs.form.setErrors(response.errors)
                }
                refCategory.errMessage = response.message
                // refCategory.errMessage = this.$t('tags.' + response.tag)
            })

        }
    }

}
</script>
