<template>
    <validation-observer
        ref="form"
        v-slot="{ invalid }"
        tag="div"
    >
        <b-overlay :show="loading">
            <b-form @submit.prevent="submit">
                <b-tabs>
                    <b-tab :title="$t('general')">
                        <b-row>
                            <b-col md="6">
                                <custom-input
                                    v-model="category.name"
                                    :name="$t('name')"
                                    required
                                    rules="required|min:2|max:255"
                                    vid="name"
                                />
                            </b-col>
                            <b-col md="6">
                                <custom-input
                                    v-model="category.name_en"
                                    :name="$t('name_en')"
                                    required
                                    rules="required|min:2"
                                    vid="name_en"
                                />
                            </b-col>
                            <b-col md="6">
                                <custom-input
                                    v-model="category.slug"
                                    :name="$t('slug')"
                                    rules="max:255"
                                    vid="slug"
                                />
                            </b-col>
                            <b-col md="6">
                                <custom-select
                                    v-model="category.status"
                                    :name="$t('status')"
                                    :options="categoryStatusOptions"
                                    required
                                    vid="status"
                                />
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="$t('options')">
                        <b-row>
                            <b-col cols="12">
                                <custom-text-area
                                    v-model="category.description"
                                    :name="$t('meta_description')"
                                    rules="max:255"
                                    vid="description"
                                />
                                <small v-if="category.description">
                                    {{ $t('characters_count',{count: category.description.length} ) }}
                                </small>
                            </b-col>
                            <b-col cols="12">
                                <custom-tags
                                    v-model="category.keywords"
                                    :name="$t('meta_keywords')"
                                    rules="max:255"
                                    vid="meta_keywords"
                                />
                            </b-col>
                            <b-col md="6">
                                <custom-input
                                    v-model="category.url"
                                    :name="$t('category.url')"
                                    dir="ltr"
                                    rules="url|max:255"
                                    vid="url"
                                />
                            </b-col>
                            <b-col md="6" class="mb-1">
                                <custom-fm-button
                                    v-model="category.img"
                                    :name="$t('category.img')"
                                    rules="max:255"
                                    vid="img"
                                />
                            </b-col>
                            <b-col md="12">
                                <label class="col-form-label">{{ $t('description') }}</label>
                                <ckeditor-custom v-model="category.body" :content="category.body"/>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
                <b-alert
                    :show="errMessage !== null"
                    class="p-2"
                    dismissible
                    variant="danger"
                >
                    {{ errMessage }}
                </b-alert>

                <b-button
                    :disabled="invalid"
                    class="mt-2"
                    type="submit"
                    variant="primary"
                >
                    {{ $t('save') }}
                </b-button>
            </b-form>
        </b-overlay>
    </validation-observer>
</template>

<script>
import { BAlert, BTab, BTabs } from "bootstrap-vue";
import { categoryStatusOptions } from "@/common/data";
import CategoryStatus from "@/constans/CategoryStatus";
import CustomFmButton from "@/layouts/components/form/CustomFmButton";
import { mapGetters } from "vuex";
import CustomTags from "@/layouts/components/form/CustomTags";
import ckeditorCustom from "@/layouts/components/form/CKeditor";

export default {
    name: "Form",

    components: {
        CustomFmButton,
        ckeditorCustom,
        CustomTags,
        BAlert,
        BTabs,
        BTab
    },

    props: {
        submit: {
            type: Function,
            required: true,
        },
        category: {
            default() {
                return {
                    name: '',
                    name_en: '',
                    slug: '',
                    status: CategoryStatus.INACTIVE,
                    keywords: [],
                    description: '',
                    url: '',
                    img: '',
                    body: ''
                }
            },
            type: Object
        }
    },

    data() {
        return {
            loading: false,
            errMessage: null,
            categoryStatusOptions,
        }
    },

    computed: {
        ...mapGetters('category', [
            'status'
        ])
    },

    methods: {
        onReady(editor) {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        }
    }
}
</script>
